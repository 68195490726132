export const shops = {
  tefal: {
    width: 136,
    height: 46,
    png: new URL('../../assets/img/tefal.png', import.meta.url),
    webp: new URL('../../assets/img/tefal.png?as=webp', import.meta.url),
  },
  rozetka: {
    width: 187,
    height: 45,
    png: new URL('../../assets/img/rozetka.png', import.meta.url),
    webp: new URL('../../assets/img/rozetka.png?as=webp', import.meta.url),
  },
  foxtrot: {
    width: 136,
    height: 32,
    png: new URL('../../assets/img/foxtrot.png', import.meta.url),
    webp: new URL('../../assets/img/foxtrot.png?as=webp', import.meta.url),
  },
  comfy: {
    width: 136,
    height: 32,
    png: new URL('../../assets/img/comfy.png', import.meta.url),
    webp: new URL('../../assets/img/comfy.png?as=webp', import.meta.url),
  },
  eldorado: {
    width: 135,
    height: 24,
    png: new URL('../../assets/img/eldorado.png', import.meta.url),
    webp: new URL('../../assets/img/eldorado.png?as=webp', import.meta.url),
  },
  epicentr: {
    width: 135,
    height: 34,
    png: new URL('../../assets/img/epicentr.png', import.meta.url),
    webp: new URL('../../assets/img/epicentr.png?as=webp', import.meta.url),
  },
};

export const setsLinks = {
  'easy-3': {
    tefal:
      null
  },
  'easy-13': {
    comfy: 'https://comfy.ua/nabor-posudy-so-s-emnoj-ruchkoj-tefal-l1539843-ingenio-easy-cook-clean-13-pr.html',
    eldorado: 'https://eldorado.ua/nabor-posudy-tefal-ingenio-easy-cookandclean-l1539843-13-pr/p71412699/',
    rozetka: 'https://rozetka.com.ua/ua/tefal-l1539843/p344685802/',
    tefal: 'https://shop.tefal.ua/ua/nabir-posudu-tefal-ingenio-easy-cook-clean-13-predmetiv-l1539843.html'
  },
  'xl-3': {
     epicentr: 'https://epicentrk.ua/ua/shop/nabor-posudy-ingenio-xl-intense-l1509273-tefal.html',
    rozetka: 'https://rozetka.com.ua/tefal_l1509273/p333637228/',
    foxtrot:
      'https://www.foxtrot.com.ua/ru/shop/naboriy_kastryul_i_skovorodok_tefal_l1509273_bkr_24_sm_28_sm_ruchka_xl_intence_ingenio.html',
    comfy:
      'https://comfy.ua/nabor-skovorod-so-s-emnoj-ruchkoj-tefal-l1509273-ingenio-xl-intense-3-pr.html',
    eldorado:
      'https://eldorado.ua/nabir-posudu-tefal-ingenio-xl-intense-3pr-l1509273-/p71384952/',
    tefal:'https://shop.tefal.ua/ua/nabir-tefal-ingenio-xl-intense-3-predmeti-l1509273.html',
  },
  'xl-10': {
    epicentr: 'https://epicentrk.ua/ua/shop/nabor-posudy-ingenio-xl-intense-10-predmetov-l1509473-tefal.html',
     comfy: 'https://comfy.ua/nabor-posudy-so-s-emnoj-ruchkoj-tefal-l1509473-ingenio-xl-intense-10-pr.html',
     rozetka: 'https://rozetka.com.ua/ua/tefal_l1509473/p333638263/',
    tefal: 'https://shop.tefal.ua/ua/nabir-posudu-z-10-predmetiv-tefal-ingenio-xl-intense-l1509473.html',
  },
  'natural-3': {
    epicentr:'https://epicentrk.ua/ua/shop/nabor-posudy-ingenio-natural-force-3-predmeta-l3969102-tefal.html',
     comfy: 'https://comfy.ua/ua/nabor-skovorod-so-s-emnoj-ruchkoj-tefal-l3969102-ingenio-natural-force-3-pr.html',
    rozetka: 'https://rozetka.com.ua/ua/tefal_l3969102/p333634981/',
    eldorado: 'https://eldorado.ua/nabir-skovoridok-tefal-ingenio-mineralia-force-3pr-l3969102-/p71385107/',
    tefal:'https://shop.tefal.ua/ua/nabir-posudu-z-3-predmetiv-tefal-ingenio-natural-force-l3969102.html',
  },
  'daily-4': {
     
    rozetka: null,
    foxtrot: null,
    comfy: null,
    eldorado: null,
    epicentr: null,
  },
  'unlimited-3': {
    epicentr: 'https://epicentrk.ua/ua/shop/nabor-posudy-ingenio-unlimited-3-predmeta-l7639142-tefal.html',
    comfy: 'https://comfy.ua/nabor-skovorod-so-s-emnoj-ruchkoj-tefal-l7639142-ingenio-unlimited-3-pr.html',
    rozetka: 'https://rozetka.com.ua/ua/tefal-l7639142/p335681149/',
    eldorado:
      'https://eldorado.ua/nabir-skovoridok-tefal-ingenio-unlimited-3pr-l7639142-/p71385082/',
    foxtrot:
      'https://www.foxtrot.com.ua/ru/shop/naboriy_kastryul_i_skovorodok_tefal_l7639142_bkr_24_sm_26_sm_ruchka_unlimited_ingenio.html',
    tefal: 'https://shop.tefal.ua/ua/nabir-posudu-z-3-predmetiv-tefal-ingenio-unlimited-l7639142.html',
  },
  'unlimited-13': {
    
    rozetka: 'https://rozetka.com.ua/ua/tefal_l7639002/p333641785/',
    foxtrot:
      'https://www.foxtrot.com.ua/ru/shop/naboriy_kastryul_i_skovorodok_tefal_l7639002_unlimited_ingenio_nabir_z_13_predmetiv.html',
    comfy:
      'https://comfy.ua/nabor-posudy-so-s-emnoj-ruchkoj-tefal-l7639002-ingenio-unlimited-13-pr.html',
    eldorado:
      'https://eldorado.ua/nabir-posudu-tefal-ingenio-unlimited-13pr-l7639002-/p71385104/',
    epicentr: null,
  },
  'authentic-6': {
    
    rozetka: 'https://rozetka.com.ua/tefal_l6719452/p119858629/',
    foxtrot:
      'https://www.foxtrot.com.ua/ru/shop/nabori_kasrtul_i_skovorodok_tefal_l6719452-authentic-ingenio-nabir-z-6-predmetiv.html',
    comfy:
      'https://comfy.ua/ua/nabor-posudy-tefal-l6719452-ingenio-authentic-6-pr.html',
    eldorado:
      'https://eldorado.ua/nabir-posudu-tefal-ingenio-authentic-6pr-l6719452-/p71290683/',
    epicentr:
      'https://epicentrk.ua/ua/shop/nabor-posudy-ingenio-authentic-l6719452-tefal.html',
  },
  'eco-3': {
   
    rozetka: 'https://rozetka.com.ua/ua/tefal_l6759103/p285781513/',
    foxtrot:
      'https://www.foxtrot.com.ua/ru/shop/skovorodki_tefal_l6759103-b-kr-22sm-26sm-ruchka-eco-respect-ingenio.html',
    comfy:
      'https://comfy.ua/nabor-skovorod-tefal-l6759103-ingenio-eco-respect-3-pr.html',
    eldorado:
      'https://eldorado.ua/nabir-posudu-tefal-ingenio-eco-respect-3pr-l6759103-/p71326198/',
    epicentr:
      'https://epicentrk.ua/ua/shop/nabor-skovorod-ingenio-eco-respect-22-sm-26-sm-so-semnoy-ruchkoy-l6759103-tefal.html',
    tefal: 'https://shop.tefal.ua/ua/2100112382.html',
  },
  'emotion-8': {
    
    rozetka: 'https://rozetka.com.ua/ua/tefal-l948s804/p336818464/',
    foxtrot: 'https://www.foxtrot.com.ua/ru/shop/naboriy_kastryul_i_skovorodok_tefal_l948s804_nabir_8_predmetiv_emotion_ingenio.html',
    comfy: 'https://comfy.ua/ua/nabor-posudy-so-s-emnoj-ruchkoj-tefal-l948s804-emotion-8-pr.html',
    eldorado: 'https://eldorado.ua/nabor-posudy-tefal-ingenio-emotion-12-pr-l948sc04/p71373632/',
  },
  'emotion-3': {
    epicentr: 'https://epicentrk.ua/ua/shop/nabir-posudu-ingenio-emotion-3-predmeta-l897s374-tefal.html',
    comfy:'https://comfy.ua/nabor-skovorod-so-s-emnoj-ruchkoj-tefal-l897s374-ingenio-emotion-3-pr.html',
  rozetka: 'https://rozetka.com.ua/ua/tefal_l897s374/p355737885/',
    tefal:
      'https://shop.tefal.ua/ua/nabir-posudu-z-3-predmetiv-tefal-ingenio-emotion-l897s374.html',
    
  },
  'emotion-10': {
    epicentr:
      'https://epicentrk.ua/ua/shop/nabor-posudy-ingenio-emotion-10-predmetov-l897sa74-tefal.html',
    comfy:'https://comfy.ua/nabor-posudy-so-s-emnoj-ruchkoj-tefal-l897sa74-ingenio-emotion-10-pr.html',
    foxtrot: 'https://www.foxtrot.com.ua/uk/shop/naboriy_kastryul_i_skovorodok_tefal_l897sa74_nabir_10_predmetiv_emotion_new_ingenio.html',
    eldorado: 'https://eldorado.ua/nabir-posudu-tefal-ingenio-emotion-10pr-l897-sa74-/p71385014/',
     rozetka: 'https://rozetka.com.ua/ua/tefal_l897sa74/p353780733/',
    tefal:
      'https://shop.tefal.ua/ua/nabir-posudu-z-10-predmetiv-tefal-ingenio-emotion-l897sa74.html',
    
  },
  'emotion-15': {
    
    rozetka: 'https://rozetka.com.ua/ua/tefal-l925sf14/p335083372/',
    comfy:
      'https://comfy.ua/nabor-posudy-so-s-emnoj-ruchkoj-tefal-l925sf14-ingenio-emotion-15-pr.html',
    eldorado:
      'https://eldorado.ua/nabir-posudu-tefal-ingenio-emotion-15pr-l925-sf14-/p71385040/',
  },
  'ecoresist': {
    comfy: null,
  },
  'jamie_oliver': {
    epicentr:'https://epicentrk.ua/ua/shop/nabor-posudy-ingenio-ot-jamie-oliver-3-predmeta-l9569232-tefal.html',
     comfy: 'https://comfy.ua/nabor-posudy-so-s-emnoj-ruchkoj-tefal-l9569232-ingenio-jamie-oliver-3-pr.html',
    foxtrot: 'https://www.foxtrot.com.ua/ru/shop/naboriy_kastryul_i_skovorodok_tefal_l9569232_bkr_28sm_kovsh_ruchka_jamie_oliver_ingenio.html',
    eldorado: 'https://eldorado.ua/nabir-posudu-tefal-ingenio-jamie-oliver-3pr-l9569232-/p71364829/',
    rozetka: 'https://rozetka.com.ua/ua/tefal_l9569232/p323050006/',
    tefal: 'https://shop.tefal.ua/ua/nabir-posudu-z-3-predmetiv-tefal-ingenio-by-jamie-oliver-l9569232-2100112855.html',
  },
  main: {
    tefal: 'https://shop.tefal.ua/ua/znizhki-do-65-na-nabori-posudu-ta-aksesuari-tefal-ingenio?page=1&sort=position%2CASC',
    comfy: 'https://comfy.ua/ua/pans-tefal-ingenio/',
    rozetka: 'https://rozetka.com.ua/ua/search/?text=tefal+Ingenio&producer=tefal&page=1', 
    foxtrot: 'https://www.foxtrot.com.ua/uk/actions/41318',
    epicentr: 'https://epicentrk.ua/ua/portal/collection/ingenio-tefal.html',
    eldorado: 'https://eldorado.ua/uk/promotion/7444/',
    
    
  },
};

export const generateLinkWithPicture = (shop, url) => {
  const picture = document.createElement('picture');
  const img = document.createElement('img');
  const sourcePng = document.createElement('source');
  const sourceWebp = document.createElement('source');
  const link = document.createElement('a');

  const imageInfo = shops[shop];

  sourceWebp.src = imageInfo.webp;
  sourceWebp.type = 'image/webp';
  sourcePng.src = imageInfo.png;
  sourcePng.type = 'image/png';
  img.alt = shop;
  img.width = imageInfo.width;
  img.height = imageInfo.height;
  img.src = imageInfo.png;

  picture.append(sourceWebp, sourcePng, img);

  link.href = url;
  link.target = '_blank';

  link.append(picture);

  return link;
};
