import * as $ from 'jquery';
import { getSection } from '../utils';
import 'slick-carousel';

export const initPan = () => {
  console.log('initPan');
  const $sectionPan = $('#section-pan');
  const $anim = $sectionPan.find('.anim');
  const $row = $sectionPan.find('.row');
  const $sectionWrap = $sectionPan.find('.section-wrap');
  const $slider = $sectionPan.find('.slider');

  const lastSection6State = 3;
  const getSectionPanRow = () => {
    let row = 0;
    for (let i = 1; i <= lastSection6State; i++) {
      const position = $slider.find('.row' + i).offset().top;
      const screenPos =
        i < 5 ? window.innerHeight / 2 : window.innerHeight / 1.5;
      if ($(document).scrollTop() + screenPos > position) {
        row = i;
      }
    }
    return row;
  };

  const setSection6State = (state) => {
    if (!$anim.hasClass('state' + state)) {
      $anim.removeClass('state5 state4 state3 state2 state1 state0');
      $anim.addClass('state' + state);
      handleResize();
    }
  };

  const handleResize = () => {
    if (window.matchMedia('screen and (max-width: 768px)').matches) {
      if (!$slider.hasClass('slick-slider')) {
        $slider.slick({
          dots: true,
          arrows: true,
          slidesToShow: 1,
        });
      }

      return;
    }
    if ($slider.hasClass('slick-slider')) {
      $slider.slick('unslick');
    }
    $row.outerHeight($('#section-pan .pan:visible').outerHeight());
    if (
      !$anim.hasClass('fixedd') &&
      $anim.hasClass('state' + lastSection6State)
    ) {
      $anim.css('top', 'auto');
      $anim.css('bottom', 1.15 * window.innerHeight);
    } else {
      $anim.css('top', '');
      $anim.css('bottom', '');
    }
  };

  const handleScroll = (e) => {
    if (window.matchMedia('screen and (max-width: 768px)').matches) {
      return;
    }
    let sTop = $(document).scrollTop();
    if (e && e.deltaY) {
      sTop += e.deltaY;
    }
    if (sTop < 0) {
      return;
    }

    const sectionPan = getSection('pan');
    const sectionDishes = getSection('dishes');

    var positionSection6 = parseInt(
      $sectionWrap.offset().top +
        $(window).outerHeight() * 0.15 -
        $sectionWrap.outerHeight() * 0.04
    );
    if (
      sectionPan &&
      sTop >= positionSection6 &&
      sectionDishes.pourcentage < 0
    ) {
      if (!$anim.hasClass('fixedd')) {
        $anim.addClass('fixedd');
        handleResize();
      }
      setSection6State(getSectionPanRow());
    } else {
      $anim.removeClass('fixedd');
      setSection6State(sectionDishes.pourcentage < 0 ? 0 : lastSection6State);
      handleResize();
    }
  };

  handleResize();
  handleScroll();

  $(window).on('resize', handleResize);
  $(document).on('scroll', handleScroll);
};
