import * as $ from 'jquery';
import 'slick-carousel';

export const initDishes = () => {
  console.log('initDishes');
  $('#section-dishes').each((index, el) => {
    const $section = $(el);
    const $slider = $section.find('.slider');
    const slides = $slider.children().length;

    $slider.slick({
      dots: slides > 3,
      arrows: slides > 3,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            dots: slides > 2,
            arrows: slides > 2,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            dots: slides > 1,
            arrows: slides > 1,
          },
        },
      ],
    });

    $slider.on('beforeChange', () => {
      $slider.find('.link-video').addClass('innactive');
    });
    $slider.on('afterChange', () => {
      $slider.find('.link-video').removeClass('innactive');
    });
  });
};
