import * as $ from 'jquery';
import 'slick-carousel';
import { generateLinkWithPicture, setsLinks } from './sets.utils';

export const initSets = () => {
  console.log('initSets');
  $('#section-sets').each((index, el) => {
    const $section = $(el);
    const $slider = $section.find('.slider');
    const slides = $slider.children().length;
    const $modal = $('.modal-sets');
    const $contentWrap = $modal.find('.content-wrap');
    const $modalOverlay = $('.modal-overlay');
    const $closeModal = $modal.find('.close-modal');
    const $shopsList = $modal.find('.shops-list');

    $slider.slick({
      dots: slides > 4,
      arrows: slides > 4,
      slidesToShow: 4,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 3,
            dots: slides > 3,
            arrows: slides > 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            dots: slides > 2,
            arrows: slides > 2,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            dots: slides > 1,
            arrows: slides > 1,
          },
        },
      ],
    });

    $slider.on('beforeChange', () => {
      $slider.find('.cta').addClass('innactive');
    });
    $slider.on('afterChange', () => {
      $slider.find('.cta').removeClass('innactive');
    });

    $slider.on('click', '.cta', (e) => {
      e.preventDefault();
      const $slide = $(e.target).closest('.slide');
      const set = $(e.target).data('set');
      const title = $slide.children('h3').prop('outerHTML');
      const image = $slide.children('picture').prop('outerHTML');
      const content = $slide.children('.content').prop('outerHTML');

      $contentWrap.html(title + image + content);

      if (
        set &&
        set in setsLinks &&
        Object.values(setsLinks[set]).filter(Boolean).length
      ) {
        Object.entries(setsLinks[set]).forEach(([shop, link]) => {
          if (!link) {
            return;
          }
          $shopsList.append(generateLinkWithPicture(shop, link));
        });
      } else {
        $shopsList.html('<span>Очікуйте скоро в наявності</span>');
      }

      $modal.addClass('show');
      $modalOverlay.addClass('show');
    });

    $closeModal.on('click', (e) => {
      e.preventDefault();
      $modal.removeClass('show');
      $modalOverlay.removeClass('show');
      $contentWrap.html('');
      $shopsList.html('');
    });
  });
};
