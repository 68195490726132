import * as $ from 'jquery';

const computeSection = ($section) => {
  const sTop = $(document).scrollTop() - 50;
  const sectionIndex = parseInt($section.data('section'));
  const sectionTop =
    $section.position().top + parseFloat($section.css('margin-top'));
  const sectionHeight = $section.outerHeight();
  const sectionScroll = sTop - sectionTop + window.innerHeight;
  let pourcentage = sectionScroll / sectionHeight;
  if (pourcentage < -1) {
    pourcentage = -1;
  }
  if (pourcentage > 1) {
    pourcentage = 1;
  }
  return {
    num: sectionIndex + 1,
    position: sectionTop,
    height: sectionHeight,
    current:
      sTop + window.innerHeight / 2 > sectionTop &&
      sTop + window.innerHeight / 2 < sectionTop + sectionHeight,
    pourcentage: pourcentage,
  };
};

export function getSection(n) {
  const $section = $('#section-' + n);
  if (!$section.length) {
    return null;
  }
  return computeSection($section);
}

export function getCurrentSection() {
  if ($(document).scrollTop() === 0) {
    return '1';
  }
  let result = null;
  $('.section[data-section]').each((i, sectionEl) => {
    const $section = $(sectionEl);
    const sectionObj = computeSection($section);
    if (sectionObj.current) {
      result = $section.data('section');
      return false;
    }
  });
  return result && result.toString();
}

export function getVideoId(url) {
  var video_id = url.split('v=')[1];
  var ampersandPosition = video_id.indexOf('&');
  if (ampersandPosition != -1) {
    return video_id.substring(0, ampersandPosition);
  }
  return video_id;
}

export function isMobile() {
  return window.matchMedia('(max-width: 767px)').matches;
}

export function trackPreview(url) {
  if (isdefined('_gaq')) _gaq.push(['_trackPageview', url]);
}

export function isdefined(variable) {
  return typeof window[variable] == 'undefined' ? false : true;
}

export function initSite() {
  console.log('initSite');

  const $modalVideo = $('.modal-video');
  const $modalVideoIframe = $modalVideo.find('iframe');
  const $modalVideoClose = $modalVideo.find('.close-modal');
  const $modalOverlay = $('.modal-overlay');

  $(document).on('click', '.link-video', function (event) {
    var href = $(this).attr('href');
    if (href.indexOf('youtube') !== -1 && !$(this).hasClass('innactive')) {
      event.preventDefault();
      var video_id = getVideoId(href);
      if (video_id) {
        $modalVideoIframe.attr(
          'src',
          'https://www.youtube.com/embed/' + video_id + '?rel=0&autoplay=1'
        );
        $modalVideo.addClass('show');
        $modalOverlay.addClass('show');
      }
    }
  });

  $modalOverlay.on('click', () => {
    $('.modal.show .close-modal').trigger('click');
  });

  $modalVideoClose.click(function (e) {
    e.preventDefault();
    $modalVideoIframe.attr('src', '');
    $modalVideo.removeClass('show');
    $modalOverlay.removeClass('show');
  });
}
