import * as $ from 'jquery';
import { generateLinkWithPicture, setsLinks } from './sets.utils';

export const initModalMain = () => {
  console.log('initModalMain');

  const $modal = $('.modal-main');
  const $close = $modal.find('.close-modal');
  const $contentWrap = $modal.find('.content-wrap');
  const $shopsList = $modal.find('.shops-list');
  const $modalOverlay = $('.modal-overlay');
  const $modalButtons = $('.cta.main-popup');

  const set = 'main';

  if (set && set in setsLinks) {
    Object.entries(setsLinks[set]).forEach(([shop, link]) => {
      if (!link) {
        return;
      }
      $shopsList.append(generateLinkWithPicture(shop, link));
    });
  }

  const showModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    $modal.addClass('show');
    $modalOverlay.addClass('show');
  };

  const closeModal = (e) => {
    e.preventDefault();
    $modal.removeClass('show');
    $modalOverlay.removeClass('show');
    $contentWrap.remove();
  };

  //showModal();


  $modalButtons.on('click', showModal);

  $modal.on('click', (e) => {
    if ($modal.is(e.target)) {
      closeModal(e);
    }
  });

  $modalOverlay.on('click', closeModal);
  $close.on('click', closeModal);
};
