import * as $ from 'jquery';
import 'slick-carousel';

export const initOffers = () => {
  console.log('initOffers');
  $('#section-offers').each((index, el) => {
    const $section = $(el);
    const $slider = $section.find('.slider');
    const slides = $slider.children().length;

    $slider.slick({
      dots: slides > 4,
      arrows: slides > 4,
      slide: 'div',
      rows: 0,
      slidesToShow: 4,
    });
  });
};
