import * as $ from 'jquery';
import { getSection, initSite } from './utils';
import { initOffers } from './sections/offers';
import { initSets } from './sections/sets';
import { initDishes } from './sections/dishes';
import { initSticky } from './sticky-container';
import { initPan } from './sections/pan-animation';
import { initModalMain } from './sections/modal-main';

const STICKY_HEIGHT = 50;

const eltScroll = document;

function init() {
  console.log('init');
  initSite();
  initModalMain();
  initSticky();
  initPan();
  initOffers();
  initSets();
  initDishes();

  $('#section-8 .slider').slick({ dots: true, arrows: true, slide: 'a' });
  $(window).resize(onResize);
  onResize();
  $(eltScroll).on('scroll', onScrollDesktop);

  $(window).on('load', function () {
    onResize();
    onScrollDesktop();
    try {
      itemsSection();
    } catch (e) {
      console.error(e);
    }
  });
}

var scrollTimeout = 0;

const $sectionHand = $('#section-hand');
const $sectionHandAnim = $sectionHand.find('.anim');

function onScrollDesktop(e) {
  let sTop = $(eltScroll).scrollTop();
  if (e && e.deltaY) {
    sTop += e.deltaY;
  }
  if (sTop < 0) {
    return;
  }
  const sectionHand = getSection('hand');

  var positionSectionHandTop = parseInt($sectionHand.offset().top + 1);
  if (sectionHand && positionSectionHandTop < sTop) {
    if (!$sectionHandAnim.hasClass('moving')) {
      $sectionHandAnim.addClass('moving');
    }
  } else {
    if (sectionHand && sectionHand.pourcentage < 1) {
      if ($sectionHandAnim.hasClass('moving')) {
        $sectionHandAnim.removeClass('moving');
      }
    }
  }

  if (e) {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }
    scrollTimeout = setTimeout(onScrollDesktop, 100);
  }
}

function itemsSection() {
  console.log('itemsSection');
  const $section2 = $('#section-items');
  if (!$section2.length) {
    return;
  }
  const $items = $section2.find('.items > .item');

  const scrollHandler = () => {
    const offsetTop = parseInt($section2.position().top + 1);
    const scrollY = window.scrollY + STICKY_HEIGHT;
    const itemHeight = $items.eq(0).outerHeight();
    const scrollHeight = itemHeight * $items.length;
    $section2.height(scrollHeight);
    const maxScroll = offsetTop + scrollHeight - itemHeight;

    $items.each((index, item) =>
      $(item).css({ top: itemHeight * index, position: 'absolute' })
    );

    $items.removeClass('fixed');
    if (scrollY >= offsetTop && scrollY < maxScroll) {
      const index = Math.floor(Math.abs(offsetTop - scrollY) / itemHeight);
      $items.eq(index).addClass('fixed');
    }
  };

  scrollHandler();

  $(window).on('scroll resize', scrollHandler);
}

function onResize() {
  const fontSize = Math.max(16, Math.min(parseInt(22 * getRatio()), 22));
  $('html').css('font-size', fontSize + 'px');
  $('#section-items').outerHeight(3 * $('#section-items .item').outerHeight());
  $('#section-store .video').height($('#section-store video').height());
  $('#section-hand .anim img').width($sectionHand.outerHeight() * 1);
}

function getRatio() {
  return $(window).width() / 1366;
}

$(document).ready(init);
