import * as $ from 'jquery';
import { getCurrentSection } from './utils';

export const initSticky = () => {
  console.log('initSticky');

  $('.sticky-container').each(function () {
    const $stickyContainer = $(this);
    const $toggle = $stickyContainer.find('.toggle-nav');
    const $navs = $stickyContainer.find('nav a, nav button');
    const $overlay = $stickyContainer.find('.overlay');

    $toggle.on('click', () => {
      $stickyContainer.toggleClass('open');
    });

    const refreshCurrentSection = () => {
      let current = getCurrentSection();
      $navs.removeClass('current');

      if (current) {
        if (current === '6') {
          current = '5';
        }

        $navs.filter(`[data-section="${current}"]`).addClass('current');
      }
    };

    $stickyContainer.on('click', '[data-section]', function (e) {
      const section = $(this).attr('data-section');
      if (section) {
        e.preventDefault();
        $stickyContainer.removeClass('open');
        const position =
          $('.section[data-section="' + section + '"]').offset().top -
          $stickyContainer.outerHeight();

        $('html, body').animate({ scrollTop: position }, 500);
      }
    });

    $overlay.on('click', () => {
      $stickyContainer.removeClass('open');
    });

    const handleScroll = () => {
      refreshCurrentSection();
    };

    const handleResize = () => {
      refreshCurrentSection();

      if (!window.matchMedia('screen and (max-width: 960px)').matches) {
        $stickyContainer.removeClass('open');
      }
    };

    handleResize();

    $(document).on('scroll', handleScroll);
    $(window).on('resize', handleResize);
  });
};
